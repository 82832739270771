<template>
  <div class="home_top">
    <div class="inner inner1">
      <div class="left_item">
        <h1>Instant Loans up </h1>
        <h1>to ₦500,000 in 5 mins!</h1>
        <h4>Trusted by 70+ lakh customers worldwide, the HelaCash online</h4>
        <h4>loan app offers fast growing instant personal loans up to ₦500,000.</h4>
        <h4>No salary slip, bank statement or credit score needed.</h4>
        <div class="item_download" @click="downloadApp()">
          <img src="@/assets/images/loanImg/Google-play.png" alt="">
        </div>
      </div>
    </div>
    <div class="inner inner2">
      <div class="left_item">
        <h1>A financial partner for the long term</h1>
        <div>
          <img src="@/assets/images/loanImg/logo_icon_2.png" alt="">
          <p>HelaCash</p>
        </div>
      </div>
      <div class="right_item">
        <div class="item_info">
          <div class="info_tips">
            <img class="info_img1" src="@/assets/images/loanImg/app-icon-1.png" alt="">
            <h4>Up to ₦10,000 direct to</h4>
            <h4 class="bottom-20">your account</h4>
            <p>Apply in 5-10 minutes. Once approved,</p>
            <p>money is sent directly to your bank account.</p>
            <p>&nbsp;</p>
          </div>
          <div class="info_tips">
            <img class="info_img1" src="@/assets/images/loanImg/app-icon-2.png" alt="">
            <h4>90+ days to repay. No salary</h4>
            <h4 class="bottom-20">slip or credit score needed.</h4>
            <p>You only need to fill out three</p>
            <p>information forms.</p>
            <p>&nbsp;</p>
          </div>
          <div class="info_tips">
            <img class="info_img1" src="@/assets/images/loanImg/app-icon-3.png" alt="">
            <h4>Always private</h4>
            <h4 class="bottom-20">and secure</h4>
            <p>We will never call your contacts.</p>
            <p>Your contacts and other information are</p>
            <p>always private and protected.</p>
          </div>
          <div class="info_tips">
            <img class="info_img1" src="@/assets/images/loanImg/app-icon-4.png" alt="">
            <h4>Grow your</h4>
            <h4 class="bottom-20">loan limit</h4>
            <p>Repay on time to build your credit score</p>
            <p>and apply for a higher loan. Most customers</p>
            <p>2X their limits in months.</p>
          </div>
        </div>
      </div>
    </div>
    <div class="inner inner3">
      <div class="inner3_item">
        <h1>About the HelaCash loan app</h1>
        <p>With millions of app downloads and more than 7 million customers across the world, the HelaCash instant personal loan app offers trusted online mobile loans in minutes. Get instant personal cash loans without any documents – no salary slips, bank statements or credit score required (100% paperless process). Our online loan app offers competitive interest rates and a longer repayment tenure (90+ days).  HelaCash values transparent and ethical lending practices and our fee structure has no hidden costs or surprises. The HelaCash loan app offers one of the highest levels of safety, security and trust among loan apps. To apply, simply download the loan app, sign-up with your phone number and answer a few easy questions – that’s it!  Most loan decisions are fast, and take just minutes. Once our loan app approves your loan application, money is sent directly to your bank account. Repaying your  loan is easy – Pay with your debit card, Netbanking, or wallets. Start your journey with HelaCash loans today!</p>
      </div>
      <div class="inner3_item">
        <h2>Your Information Is Secure</h2>
        <p>Rest assured that when you apply for a loan with us, your data is private & secure. We will never call your family or friends under any circumstances. HelaCash does not disclose any personal or loan information with anyone that is not you. We will never sell your data to third parties. Our partnership with every customer is a private matter. We value every single customer relationship and work on principles of trust and understanding. If you have any questions or concerns, please reach out to us at helacashapp@outlook.com</p>
      </div>
    </div>
    <div class="inner inner5">
      <h1>What our customers say</h1>
      <div class="inner5_item">
        <div class="content">
          <img src="@/assets/images/loanImg/home-inner5-1.png" alt="">
          <div>
            <h2>Shila</h2>
            <h4>Google Play Review</h4>
            <p>“Excellent application for simple
              & quick loan.I will received my
              loan within 10 minutes. Giving
              more than 5  stars to HelaCash
              loan application.Thanks.”</p>
          </div>
        </div>
        <div class="content">
          <img src="@/assets/images/loanImg/home-inner5-2.png" alt="">
          <div>
            <h2>Mahadeva</h2>
            <h4>Google Play Review</h4>
            <p>“Very reliable and super fast
              application process. Thank you so
              much for helping me in my emergency
              case.I highly recommendeveryone to
              use this amazing loanapplication for
              your needs.”
            </p>
          </div>
        </div>
        <div class="content">
          <img src="@/assets/images/loanImg/home-inner5-3.png" alt="">
          <div>
            <h2>Shila</h2>
            <h4>Google Play Review</h4>
            <p>“This app is fantastic and genuine.l
              recommend everyone to use this
              appin their time of need. lt will surely
              help you.”</p>
          </div>
        </div>
      </div>
      <a class="more" href="/contact_us">
        Vlew More
      </a>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    downloadApp() {
      window.location.href = 'https://play.google.com/store/apps/details?id=fastcredit.instahela.app'
    }
  }
}
</script>

<style lang="scss" scoped>
  .home_top{
    min-width: 1200px;
    background: #fff;
    .inner{
      width: 100%;
      padding:50px calc((100% - 1100px) / 2) 50px;
      margin: 0 auto;
    }
    .bottom-20{
      margin-bottom: 20px;
    }
    .inner1{
      // background: linear-gradient(258deg, #43C5D6 0%, #4484EC 100%);
      background: url("~@/assets/images/loanImg/inner1_bg.png") no-repeat;
      background-size: 100% auto;
      height: 700px;
      padding:150px calc((100% - 1300px) / 2) 0;
      .left_item{
        background: linear-gradient(to bottom,#A4C519 0% ,#A4C519 5%, #ED7E00 5% ,#ED7E00 100%);
        float: left;
        padding: 50px;
        // border-radius: 10px;
        width: 650px;
        h1{
          font-size: 50px;
          color: white;
          font-weight: 700;
          margin-bottom: 15px;
          line-height: 50px;
        }
        h4{
          padding-right: 17.5px;
          font-size: 16px;
          line-height: 26px;
          color: #FFFFFF99;
          font-weight: normal;
          // margin-bottom: 10px;
        }
        .item_progress{
          width: 230px;
          p{
            width: 90px;
            text-align: center;
            line-height: 30px;
            border-radius: 5px;
            color: white;
            font-size: 12px;
            font-weight: bold;
            margin: 0 auto 10px;
            background: #4488EB;
          }
          img{
            width: 245px;
            height: 15px;
          }
        }
        .item_info{
          margin: 20px 0;
          display: flex;
          height: 100px;
          .info_img1{
            width: 15px;
          }
          .info_tips{
            display: flex;
            flex-direction: column;
            p{
              flex: 1;
              display: flex;
              box-sizing: border-box;
              // margin-left: 10px;
              font-size: 12px;
              color: #666666;
              align-items: center;
              img{
                margin-right: 10px;
              }
            }
          }
          .info_img2{
            width: 135px;
            height: 135px;
          }
        }
        .item_download{
          display: flex;
          margin-top: 20px;
          width: 140px;
          // background: black;
          color: white;
          border-radius: 5px;
          align-items: center;
          justify-content: center;
          img{
            width: 140px;
            height: 45px;
            margin-right: 5px;
          }
        }
      }
      .right_item{
        float: right;
        // margin-right: 50px;
        img{
          width: 550px;
        }
      }
    }
    .inner2{
      background:#F8F9FF;
      display: flex;
      flex-direction: column;
      height: 600px;
      padding-top: 100px;
      .left_item{
        flex: 1;
        background: url("~@/assets/images/loanImg/inner2_bg.png") no-repeat;
        background-size: 100% auto;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        h1{
          margin-left: 30px;
          text-align: center;
          width: 370px;
          font-weight: bold;
          line-height: 40px;
          font-size: 35px;
        }
        div{
          position: absolute;
          right: 20px;
          top: calc(50% - 40px);
          img{
            display: block;
            width: 60px;
            margin: 0 auto 15px;
          }
          p{
            font-weight: bold;
            font-size: 16px;
          }
        }
      }
      .right_item{
        flex: 1.3;
        h1{
          font-size: 38px;
          line-height: 47px;
        }
        p{
          // margin-top: 20px;
          color:#2665D9 ;
          font-size: 20px;
          font-weight: bold;
        }
        .item_info{
          display: flex;
          background: white;
          .info_img1{
            width: 30px;
            height: 25px;
            margin-bottom: 25px;
          }
          .info_tips{
            display: flex;
            flex-direction: column;
            background: #2E373D;
            margin-right: 5px;
            flex: 1;
            align-items: center;
            justify-content: center;
            padding: 30px 10px 25px;
            text-align: center;
            h4{
              color: white;
              line-height: 20px;
              font-weight: normal;
            }
            p{
              display: flex;
              box-sizing: border-box;
              font-size: 12px;
              color: #ffffff99;
              align-items: center;
              line-height: 18px;
              font-weight: normal;
              img{
                margin-right: 10px;
              }
            }
            & h4:nth-last-child(1){
              margin-bottom:20px;
            }
          }
          & .info_tips:nth-last-child(1){
            margin-right: 0px;
          }
        }
      }
    }
    .inner3{
      background: url("~@/assets/images/loanImg/inner3_bg.png") no-repeat;
      background-size: 100% auto;
      height: 800px;
      padding: 50px calc((100% - 100px)/ 2) 50px calc((100% - 1300px) / 2);
      h3{
        font-size: 35px;
        margin:50px 0;
      }
      h1,h2{
        text-align: center;
        margin-bottom: 30px;
      }
      .inner3_item{
        background: white;
        margin: 50px 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 35px;
        opacity: 0.9;
        p{
          line-height: 20px;
        }
      }
    }
    .inner5{
      padding:60px calc((100% - 1100px) / 2) 100px;
      h1{
        text-align: center;
        margin-bottom: 50px;
        font-size: 35px;
      }
      .inner5_item{
        width: 100%;
        display: flex;
        justify-content: center;
        font-size: 14px;
        text-align: center;
        line-height: 16px;
        .content{
          width: 300px;
          display: flex;
          flex-direction: column;
          margin-right: 50px;
          img{
            width: 100%;
            height: 300px;
          }
          div{
            background: url("~@/assets/images/loanImg/inner5_bg.png") no-repeat;
            background-size: 100% 100%;
            width: 95%;
            margin: -100px 0 0;
            padding: 20px 30px;
            height: 100%;
            h2,h4,p{
              color: #FFFFFF70;
              text-align: start;
            }
            h2{
              color: #FFFFFF99;
            }
            h4{
              line-height: 40px;
              border-bottom:1px solid  #FFFFFF70;
              margin-bottom: 20px;
            }
          }
        }
      }
      .more{
        display: block;
        color: #ED7E00;
        border-radius: 20px;
        border: 1px solid #ED7E00;
        width: 140px;
        margin: 60px auto 0;
        text-align: center;
        line-height: 40px;
        font-size: 16px;
      }
    }
  }
@media only screen and (max-width: 720px){
  .home_top{
    min-width: calc(100vw) !important;
    .inner{
      min-width: calc(100vw) !important;
      display: flex;
      flex-wrap: wrap;
    }
    .inner1{
      height: 620px;
      position: relative;
      h1{
        font-size: 30px !important;
        line-height: 35px !important;
      }
      .left_item{
        margin: 25px 0;
        height: 450px;
      }
      .right_item{
        width: calc(100vw);
        display: flex;
        justify-content: center;
        align-items: center;
        img{
          width: 350px !important;
          position: absolute;
          bottom: 0;
        }
      }
    }
    .inner2{
      padding: 40px 20px 0;
      height: 1200px;
      flex-direction: row;
      .left_item{
        position: relative;
        h1{
          width: 100%;
          font-size: 16px;
          margin-left: 0;
          margin-top: 60px;
        }
        div{
          position: absolute;
          top: 15px;
          img{
            width: 20px;
            height: 20px;
            margin: 0 auto 5px;
          }
        }
      }
      .right_item{
        margin-right: 0;
        width: calc(100vw - 40px);
        p{
          font-size: 18px;
        }
        // img{
        //   // width: 100%;
        //   // height: 60px;
        // }
        .item_info{
          flex-direction: column;
          background: #F8F9FF;
        }
        .info_tips{
          width: calc(100vw - 40px);
          margin-bottom: 20px;
          margin-right: 0px;
          p{
            font-size: 12px !important;
          }
        }
      }
    }
    .inner3{
      width: calc(100vw - 40px) !important;
      margin: 0 auto  30px !important;
      padding: 200px 0 0 0;
      height: 1200px;
      h3{
        font-size: 18px;
        padding: 0 20px;
      }
      img{
        width: 90%;
        margin: 0 auto;
      }
      .inner3_item{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 0;
        .content{
          margin: 20px 0;
        }
      }
    }
    .inner5{
      min-width: calc(100vw - 40px) !important;
      width: calc(100vw - 40px) !important;
      margin: 0 auto !important;
      padding: 0 0 50px 0;
      h3{
        font-size: 18px;
      }
      .inner5-bg{
        padding: 20px  !important;
        height: 100px !important;
        font-size: 12px !important;
      }
      .inner5_item{
        margin:  0 auto;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        .content{
          margin: 20px 0;
        }
      }
    }
  }
}
</style>
